<template>
    <div class="dateTimeSelection flex-1 right-10">
        <ejs-datepicker
            format="EEEE, MM/dd/yyyy"
            :value="date"
            @change="handleDateChange"
        />
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState } from 'vuex';
    import { DatePickerPlugin } from '@syncfusion/ej2-vue-calendars';

    Vue.use(DatePickerPlugin);

    export default {
        name: 'DatePicker',
        computed: {
            ...mapState('eventEditor', {
                date: (state) => state.event.dayt_appt_start,
            }),
        },
        methods: {
            handleDateChange(e) {
                this.$store.dispatch('eventEditor/updateDate', e.value);
            }
        }
    };
</script>
